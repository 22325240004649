import * as React from "react";
import { AskAboutContext } from "../../../../../site-context/ask-about-context";
import {
  setCountryInputFocused,
  setSessionUserCountry,
  cleanUserCountry,
} from "../../../../../site-context/store/user-country";
import { useClickOutHooks } from "../../../../../hooks/use-click-out-hooks";
import Image from "../../../../ui-library/image/image";
import CountrySuggestionBox from "./country-suggestion-box";
import styles from "./country-suggest.styles";
import clearInputIcon from "./clear-icon.svg";

const CountrySuggest: React.FC = () => {
  const {
    store: {
      userCountry: [{ country, isFocused }, dispatch],
    },
  } = React.useContext(AskAboutContext);

  const inputRef = React.useRef<HTMLDivElement>(null);
  const [countryTerm, setCountryTerm] = React.useState<string>(
    country?.countryName || ""
  );

  React.useEffect(() => {
    dispatch(setSessionUserCountry());
    return () => {
      dispatch(setCountryInputFocused(false));
    };
  }, []);

  React.useEffect(() => {
    setCountryTerm(country?.countryName || countryTerm);
  }, [country]);

  useClickOutHooks({
    containerRef: inputRef,
    callback: () => dispatch(setCountryInputFocused(false)),
    isFocused,
  });

  const clearCountry = () => {
    dispatch(setCountryInputFocused(false));
    dispatch(cleanUserCountry());
    setCountryTerm("");
  };

  return (
    <div ref={inputRef} css={styles.container}>
      <div css={[styles.inputContainer, isFocused && styles.inputFocus]}>
        <div
          css={[styles.indicator, !!country && styles.highlightedIndicator]}
        />
        <input
          type="text"
          css={styles.input}
          value={countryTerm}
          placeholder={`Search for a country`}
          onFocus={() => {
            dispatch(setCountryInputFocused(true));
          }}
          onChange={(e) => setCountryTerm(e.target.value)}
        />
        {countryTerm !== "" && country && (
          <div
            onClick={clearCountry}
            css={[
              styles.cancelInputButton,
              !isFocused &&
                countryTerm.length === 0 &&
                styles.cleanInputButtonHidden,
            ]}
          >
            <Image src={clearInputIcon} alt="Clear" turnOffLazyLoading={true} />
          </div>
        )}
      </div>

      <CountrySuggestionBox
        countryTerm={countryTerm}
        onClickItem={setCountryTerm}
      />
    </div>
  );
};

export default CountrySuggest;
