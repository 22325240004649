import * as React from "react";
import { EventCategory } from "../../../../../utils/analytics";
import { AskAboutContext } from "../../../../../site-context/ask-about-context";
import WorldSnapshotCard from "./world-snapshot-card";

const WorldSnapshot: React.FC = () => {
  const {
    store: {
      userCountry: [{ country }],
    },
  } = React.useContext(AskAboutContext);

  return (
    <WorldSnapshotCard
      country={country?.country}
      countryName={country?.countryName}
      alias={country?.alias}
      eventCategory={EventCategory.GlobalSnapshot}
    />
  );
};

export default WorldSnapshot;
