import * as React from "react";
import formatDate from "dateformat";
import { useQuery } from "@apollo/react-hooks";
import ContentCard from "../../../../ui-library/content-card/content-card";
import CardContent from "../../../../ui-library/content-card/card-content";
import CardHeading from "../../../../ui-library/content-card/card-heading";
import {
  CovidCaseByCountry,
  CovidCaseByCountryVariables,
} from "../../../../../apollo-types/CovidCaseByCountry";
import { EventCategory } from "../../../../../utils/analytics";
import CountrySuggest from "../country-suggest/country-suggest";
import { COVID_CASES_BY_COUNTRY } from "../query";
import LoadingState from "../../../../ui-library/loading-state/loading-state";
import CardSubHeading from "../../../../ui-library/content-card/card-sub-heading";
import {
  getCountryUrl,
  getCOVIDIntentUrl,
} from "../../../../../utils/question-url";
import { AskAboutContext } from "../../../../../site-context/ask-about-context";
import LinkButton from "../../../../ui-library/link-button/link-button";
import { SPECIAL_INTENTS } from "../../../../../constants/special-intents";
import CountryProgressBar from "./country-progress-bar";
import CountrySnapshot from "./country-snapshot";

interface WorldSnapshotCardProps {
  countryName?: string;
  country?: string;
  alias?: string;
  eventCategory: EventCategory;
}

const WorldSnapshotCard: React.FC<WorldSnapshotCardProps> = ({
  countryName = "World",
  country = "World",
  alias = "world",
  eventCategory,
}) => {
  const {
    store: { isMobile },
  } = React.useContext(AskAboutContext);

  const { data, loading } = useQuery<
    CovidCaseByCountry,
    CovidCaseByCountryVariables
  >(COVID_CASES_BY_COUNTRY, {
    variables: { country },
  });

  const url =
    alias === "world"
      ? getCOVIDIntentUrl(SPECIAL_INTENTS.GlobalSituation.alias)
      : getCountryUrl(alias);

  if (loading) {
    return (
      <ContentCard>
        <CardContent>
          <CountrySuggest />

          <CardHeading>
            {countryName === "World"
              ? "What’s the global situation?"
              : `What's the situation in ${countryName}`}
          </CardHeading>

          {!isMobile && (
            <CardSubHeading>
              These are confirmed cases only. Actual infections may vary as
              testing regimes never track everyone.
            </CardSubHeading>
          )}

          <LoadingState height={290} />

          <LinkButton
            href={url}
            isCenter={true}
            analytics={{
              category: eventCategory,
              action: "Click - See more details",
              label: "See more details",
            }}
          >
            See more details
          </LinkButton>
        </CardContent>
      </ContentCard>
    );
  }

  const covidData = data?.covidCaseOwidByCountry?.data;

  const lastDayValue = data?.covidCaseOwidByCountry?.latest;

  if (!covidData || !lastDayValue) {
    return null;
  }

  const headingDetail = `Last updated ${formatDate(
    lastDayValue.date,
    "dd mmm yyyy"
  )}`;

  return (
    <ContentCard>
      <CardContent>
        <CountrySuggest />

        <CardHeading headingDetail={headingDetail}>
          {countryName === "World"
            ? "What’s the global situation?"
            : `What's the situation in ${countryName}`}
        </CardHeading>

        {!isMobile && (
          <CardSubHeading>
            These are confirmed cases only. Actual infections may vary as
            testing regimes never track everyone.
          </CardSubHeading>
        )}

        <CountrySnapshot
          lastDayValue={lastDayValue}
          countryPageUrl={url}
          covidData={covidData}
          isMobileScrollable={true}
        />
        <CountryProgressBar {...lastDayValue} />

        <LinkButton
          href={url}
          isCenter={true}
          analytics={{
            category: eventCategory,
            action: "Click - See more details",
            label: "See more details",
          }}
        >
          See more details
        </LinkButton>
      </CardContent>
    </ContentCard>
  );
};

export default WorldSnapshotCard;
