import * as React from "react";
import Fuse from "fuse.js";
import { AskAboutContext } from "../../../../../site-context/ask-about-context";
import { useCountriesQuery } from "../../../../../hooks/static-queries/use-countries-query";
import { formatNumber } from "../../../../../utils/format-number";
import {
  setCountryInputFocused,
  setUserCountry,
} from "../../../../../site-context/store/user-country";
import { AskAboutQuery_CountryItem } from "../../../../../../graphql-types";
import styles from "./country-suggestion-box.styles";

interface CountrySuggestionBoxProps {
  countryTerm: string;
  onClickItem: (countryName: string) => void;
}

const options = {
  isCaseSensitive: false,
  shouldSort: true,
  threshold: 0.3,
  keys: ["displayName", "name"],
};

const CountrySuggestionBox: React.FC<CountrySuggestionBoxProps> = ({
  countryTerm,
  onClickItem,
}) => {
  const {
    store: {
      userCountry: [{ isFocused }, dispatch],
    },
  } = React.useContext(AskAboutContext);

  const countries: AskAboutQuery_CountryItem[] = useCountriesQuery();
  const fuse = new Fuse(countries, options);
  const matchingCountries = fuse.search(countryTerm).map((c) => c.item);
  const countriesToDisplay =
    matchingCountries.length > 0 ? matchingCountries : countries;

  const handleCountryClick = (c: AskAboutQuery_CountryItem) => {
    onClickItem(c.displayName);
    dispatch(setCountryInputFocused(false));
    dispatch(
      setUserCountry({
        countryName: c.displayName,
        country: c.name,
        alias: c.alias,
      })
    );
  };

  return (
    <div
      css={[
        styles.root,
        !isFocused && {
          maxHeight: 0,
          border: "0 !important",
          boxShadow: "none",
        },
      ]}
    >
      {countriesToDisplay.map((c) => (
        <div
          key={c.name}
          css={styles.suggestItem}
          onClick={() => handleCountryClick(c)}
        >
          <span>{c.displayName}</span>
          <span css={styles.caseNumber}>
            {c.totalCases && `${formatNumber(c.totalCases)} cases`}
          </span>
        </div>
      ))}
    </div>
  );
};

export default CountrySuggestionBox;
